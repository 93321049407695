@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-management {
      > span {
        box-sizing: border-box;

        &:nth-child(1) {
          transform: scale(1);
          bottom: -7.5px;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          right: 0;
          z-index: 99;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
            background: $primary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
            background: $secondary;
          }

          &:before {
            content: 'Plan';
            font-size: 4px;
            line-height: 7px;
            position: absolute;
            top: -23px;
            width: 21px;
            display: block;
            height: 7px;
            left: -15px;

            border-bottom: 1px solid $primary;

            @media (prefers-color-scheme: dark) {
              border-bottom: 1px solid $secondary;
            }
          }
        }

        &:nth-child(2) {
          transform: scale(1);
          bottom: 0;
          width: 15px;
          height: 1px;
          right: 0px;
          z-index: 99;
        }

        &:nth-child(3) {
          transform: scale(1);
          bottom: 7.5px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          right: 2.5px;
          z-index: 99;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
            background: $primary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
            background: $secondary;
          }
        }

        &:nth-child(4) {
          transform: scale(1);
          top: 0px;
          width: 23px;
          height: 30px;
          left: 0px;

          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(5) {
          transform: scale(1);
          top: 10px;
          width: 4px;
          height: 1px;
          left: 4px;

          &:before {
            content: '';
            position: absolute;
            top: 0px;
            width: 8px;
            display: block;
            height: 1px;
            left: 8px;
            background: $primary;

            @media (prefers-color-scheme: dark) {
              background: $secondary;
            }
          }
        }

        &:nth-child(6) {
          transform: scale(1);
          top: 12px;
          width: 4px;
          height: 1px;
          left: 4px;

          &:before {
            content: '';
            position: absolute;
            top: 0px;
            width: 6px;
            display: block;
            height: 1px;
            left: 8px;
            background: $primary;

            @media (prefers-color-scheme: dark) {
              background: $secondary;
            }
          }
        }

        &:nth-child(7) {
          transform: scale(1);
          top: 14px;
          width: 4px;
          height: 1px;
          left: 4px;

          &:before {
            content: '';
            position: absolute;
            top: 0px;
            width: 4px;
            display: block;
            height: 1px;
            left: 8px;
            background: $primary;

            @media (prefers-color-scheme: dark) {
              background: $secondary;
            }
          }
        }

        &:nth-child(8) {
          transform: scale(1) rotate(45deg);
          bottom: 8px;
          width: 6px;
          height: 1px;
          right: 14px;
          z-index: 99;
        }

        &:nth-child(9) {
          transform: scale(1);
          bottom: 12px;
          width: 2px;
          height: 2px;
          border-radius: 100%;
          animation: scaleDot 3s infinite;
          right: 20px;
          z-index: 99;

          background: transparent;
          border: 1px solid $primary;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }
        }
      }
    }
  }
}

@keyframes scaleDot {
  0% {
    width: 2px;
    height: 2px;
  }

  50% {
    width: 4px;
    height: 4px;
  }

  51% {
    width: 2px;
    height: 2px;
  }
}
