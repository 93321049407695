@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.serviceListItem {
  height: 200px;
  width: 100%;
  position: relative;

  // background: lightskyblue;
  &:hover {
  }

  .serviceIcon {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .serviceName {
    position: absolute;
    bottom: 0;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
  }
}