@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@keyframes rotateLeft {
  from {
    transform: rotate(0deg);  
  }
  to {
    transform: rotate(360deg);
  }
}

.icon {
  &.icon {
    &-dataModelling {
      animation: rotateLeft 5s infinite;

      > span {
        box-sizing: border-box;

        &:nth-child(1) {
          transform: scale(1);
          top: 50%;
          margin-top: -0.5px;
          width: 20%;
          height: 1px;
          left: 0px;
        }

        &:nth-child(2) {
          transform: scale(1);
          top: 0;
          width: 1px;
          height: 20%;
          left: 50%;
          margin-left: -0.5px;
        }

        &:nth-child(3) {
          transform: scale(1);
          top: 50%;
          margin-top: -0.5px;
          width: 20%;
          height: 1px;
          right: 0px;
        }

        &:nth-child(4) {
          transform: scale(1);
          bottom: 0;
          width: 1px;
          height: 20%;
          left: 50%;
          margin-left: -0.5px;
        }

        &:nth-child(5) {
          transform: scaleY(1) rotate(45deg);
          top: 2px;
          width: 1px;
          height: 20%;
          right: 5px;
          margin-left: -0.5px;
        }

        &:nth-child(6) {
          transform: scale(1) rotate(-45deg);
          bottom: 2px;
          width: 1px;
          height: 20%;
          right: 5px;
          margin-left: -0.5px;
        }

        &:nth-child(7) {
          transform: scale(1) rotate(45deg);
          bottom: 2px;
          width: 1px;
          height: 20%;
          left: 5px;
          margin-left: -0.5px;
        }

        &:nth-child(8) {
          transform: scale(1) rotate(45deg);
          top: 5px;
          margin-left: -0.5px;
          width: 20%;
          height: 1px;
          left: 2px;
        }

        &:nth-child(9) {
          transform: rotate(0deg) scaleX(1);
          top: 50%;
          margin-top: -5px;
          left: 50%;
          margin-left: -5px;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }
      
          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }
      }
    }
  }
}
