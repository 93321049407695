@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.input {
  &.input {
    width: 100%;
    display: block;
    padding: 10px 5px;
    background-color: $secondary;
    text-align: center;
    @media (prefers-color-scheme: dark) {
      background-color: $primary;
    }
    margin: 2px 0;
    border: 1px solid $primary;
    border-radius: 0;
    color: $primary;
    @media (prefers-color-scheme: dark) {
      background-color: $primary;
      border: 1px solid $secondary;
      color: $secondary;
    }

    &::-webkit-input-placeholder {
      text-align: center;
      color: $primary;
      @media (prefers-color-scheme: dark) {
        color: $secondary;
      }
    }   
  }
}
  
