@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.squareText {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  cursor: cell;

  &.cursor {
    cursor: pointer;
  }

  &:hover .scalingLetter {
    color: $primary;
  }
}
