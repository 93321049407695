@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@keyframes width {
  0% {
    width: 10px;
  }

  100% {
    width: 0px;
  }
}

.icon {
  &.icon {
    &-consulting {
      overflow: visible;

      > span {
        &:nth-child(1) {
          border-radius: 100% 100% 80% 80%;
          height: 20px;
          left: 50%;
          margin-left: -10px;
          width: 20px;
          background-color: transparent;
          border: 1px solid;
          box-sizing: border-box;
          transform: scale(1);
        }

        &:nth-child(2) {
          left: 50%;
          margin-left: -4px;
          height: 2px;
          width: 8px;
          top: 22px;
          transform: scale(1);
        }

        &:nth-child(3) {
          left: 50%;
          margin-left: -3.5px;
          height: 2px;
          width: 7px;
          top: 25px;
          transform: scale(1);
        }

        &:nth-child(4) {
          left: 50%;
          margin-left: -2px;
          height: 1px;
          width: 4px;
          top: 28px;
          transform: scale(1);
        }

        &:nth-child(5) {
          left: 0;
          height: 1px;
          width: 10px;
          top: 10px;
          transform: scale(1) rotate(0deg);
          animation: width 2s infinite;
          animation-delay: 100ms;
        }

        &:nth-child(6) {
          right: 0;
          height: 1px;
          width: 10px;
          top: 10px;
          transform: scale(1) rotate(0deg);
          animation: width 2s infinite;
          animation-delay: 100ms;
        }

        &:nth-child(7) {
          right: 3px;
          height: 1px;
          width: 10px;
          top: 22px;
          transform: scaleX(0.8) rotate(25deg);
          animation: width 2s infinite;
          animation-delay: 100ms;
        }

        &:nth-child(8) {
          left: 3px;
          height: 1px;
          width: 10px;
          top: 22px;
          transform: scaleX(0.8) rotate(-25deg);
          animation: width 2s infinite;
          animation-delay: 100ms;
        }

        &:nth-child(9) {
          left: 3px;
          height: 1px;
          width: 10px;
          top: 0px;
          transform: scaleX(0.8) rotate(25deg);
          animation: width 2s infinite;
          animation-delay: 100ms;
        }

        &:nth-child(10) {
          right: 3px;
          height: 1px;
          width: 10px;
          top: 0px;
          transform: scaleX(0.8) rotate(-25deg);
          animation: width 2s infinite;
          animation-delay: 100ms;
        }
      }
    }
  }
}
