.icon {
  &-flag {
    &_de {
      filter: grayscale(1);
      -webkit-transition : -webkit-filter 200ms linear;
      transition : filter 200ms linear;

      &:hover {
        filter: grayscale(0);
      }

      &.active {
        filter: grayscale(0);
      }

      > span {
        border-radius: 0;

        &:nth-child(1) {
          width: 100%;
          height: 33.333%;
          display: block;
          position: relative;
          background: black;
          transform: scale(1);
        }

        &:nth-child(2) {
          width: 100%;
          height: 33.333%;
          position: relative;
          display: block;
          background: red;
          transform: scale(1);
        }

        &:nth-child(3) {
          width: 100%;
          height: 33.333%;
          position: relative;
          display: block;
          background: gold;
          transform: scale(1);
        }
      }
    }
  }
}