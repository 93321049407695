@import "../../../Assets/StyleSheets/Variables/_colors.scss";
@import "../../../Assets/StyleSheets/Animations/_colorFade.scss";

.circleWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;

  > .circle {
    width: 200%;
    height: 200%;
    border-radius: 50%;
    position: relative;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;

    &.animate {
      animation-name: colorFadeRainbowBackground;
    }
  }
}
