@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@keyframes flyInLeft {
  from {
    width: 0%;
  }

  to {
    width: 20%;
  }
}

@keyframes flyTop {
  from {
    height: 0%;
  }

  to {
    height: 20%;
  }
}

.icon {
  &.icon {
    &-etl {
      > span {
        box-sizing: border-box;

        &:nth-child(1) {
          transform: scaleX(1);
          top: 50%;
          margin-top: -0.5px;
          width: 20%;
          animation: flyInLeft 1s infinite;
          height: 1px;
          left: 0px;
        }

        &:nth-child(2) {
          transform: scaleX(1);
          top: 50%;
          margin-top: -0.5px;
          width: 20%;
          height: 1px;
          right: 0px;
          animation: flyInLeft 1s infinite;
        }

        &:nth-child(4) {
          transform: scaleX(1);
          top: 0px;
          width: 1px;
          height: 20%;
          left: 50%;
          margin-left: -0.5px;
          animation: flyTop 1s infinite;
        }

        &:nth-child(5) {
          transform: scaleX(1);
          bottom: 0px;
          width: 1px;
          height: 20%;
          left: 50%;
          margin-left: -0.5px;
          animation: flyTop 1s infinite;
        }

        &:nth-child(3) {
          transform: scaleX(1);
          top: 50%;
          margin-top: -5px;
          left: 50%;
          margin-left: -5px;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }
      
          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }
      }
    }
  }
}
