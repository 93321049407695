@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-webDevelopment {
      animation: transform3D 3s infinite;

      > span {
        box-sizing: border-box;

        &:nth-child(1) {
          transform: scale(1);
          bottom: 0px;
          width: 100%;
          height: 100%;
          left: 0px;

          background: transparent;
          
          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }
          
          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(2) {
          transform: scale(1);
          top: 10px;
          width: 15px;
          height: 1px;
          right: 5px;
        }

        &:nth-child(3) {
          transform: scale(1);
          top: 15px;
          width: 15px;
          height: 1px;
          right: 5px;
        }

        &:nth-child(4) {
          transform: scale(1);
          top: 20px;
          width: 15px;
          height: 1px;
          right: 5px;
        }

        &:nth-child(5) {
          transform: scale(1);
          top: 10px;
          width: 21px;
          height: 12px;
          left: 5px;

          background: transparent;
          
          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }
          
          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(6) {
          transform: scale(1);
          top: 5px;
          width: 40px;
          height: 1px;
          right: 5px;
        }
      }
    }
  }
}


@keyframes transform3D {
  0% {
    transform: rotate3d(0, 0, 0, 0deg);
  }

  10% {
    transform: rotate3d(10, 10, 10, 10deg);
  }

  20% {
    transform: rotate3d(10, 10, 10, -10deg);
  }

  30% {
    transform: rotate3d(10, 10, 10, 0deg);
  }

  100% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}
