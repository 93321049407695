@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.controller {
  display: block;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;

  &.open {
    > .controlElements {
      top: 0px;
      height: calc(100vh - 53px);
    }
  }

  .controlElements {
    position: relative;
    overflow: hidden;
    margin: 53px auto 0;
    z-index: 9999;
    height: 0;

    -webkit-transition: all 500ms linear;
    -ms-transition: all 500ms linear;
    transition: all 500ms linear;
  }

  > .menu {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(30px);

    @media (prefers-color-scheme: dark) {
      background-color: $primary;
    }

    @media (prefers-color-scheme: light) {
      background-color: $secondary;
    }
  }
}
