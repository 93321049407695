@import "../../../Assets/StyleSheets/Variables/_colors.scss";


@keyframes rotateCube {
  0% {
    transform: translateZ(0px) rotateX(10deg) rotateY(10deg);
  }

  50% {
    transform: translateZ(0px) rotateX(140deg) rotateY(350deg);
  }

  100% {
    transform: translateZ(0px) rotateX(10deg) rotateY(10deg);
  }
}

.icon {
  &.icon {
    &-development {
      transform-style: preserve-3d;
      overflow: visible;
      animation: rotateCube 5s infinite;
      animation-delay: 100ms;

      >span {
        left: 0px;
        height: 30px;
        width: 30px;
        border: 1px solid;
        box-sizing: border-box;
        background: transparent;

        &:nth-child(1) {
          transform: scale(1) rotateY(0deg) translateZ(-15px);
        }

        &:nth-child(2) {
          transform: scale(1) rotateY(90deg) translateZ(-15px);
        }

        &:nth-child(3) {
          transform: scale(1) rotateY(180deg) translateZ(-15px);
        }

        &:nth-child(4) {
          transform: scale(1) rotateY(-90deg) translateZ(-15px);
        }

        &:nth-child(5) {
          transform: scale(1) rotateX(-90deg) translateZ(-15px);
        }

        &:nth-child(6) {
          transform: scale(1) rotateX(90deg) translateZ(-15px);
        }
      }
    }
  }
}