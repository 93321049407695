@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@media (prefers-color-scheme: dark) {
  .rectangle { 
    &.borderLeft {
      border-left: 1px solid $secondary;
    }
  
    &.borderRight {
      border-right: 1px solid $secondary;
    }
  
    &.borderTop {
      border-top: 1px solid $secondary;
    }
  
    &.borderBottom {
      border-bottom: 1px solid $secondary;
    }
  }
}

@media (prefers-color-scheme: light) {
  .rectangle { 
    &.borderLeft {
      border-left: 1px solid $primary;
    }
  
    &.borderRight {
      border-right: 1px solid $primary;
    }
  
    &.borderTop {
      border-top: 1px solid $primary;
    }
  
    &.borderBottom {
      border-bottom: 1px solid $primary;
    }
  }
}

.rectangle {
  flex: 1;
  display: flex;

  &.rotated {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
