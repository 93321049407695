@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-check {
      > span {
        &:nth-child(1) {
          transform: rotate(45deg) scalex(0.4);
          top: 19px;
          left: -6px;
          height: 1px;
        }

        &:nth-child(2) {
          transform: rotate(-45deg) scalex(0.7);
          top: 16px;
          left: 5px;
          height: 1px;
        }
      }
    }
  }
}