@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@keyframes flyInLeftFullWidth {
  0% {
    width: 0px;
  }

  50% {
    width: 17px;
  }

  100% {
    width: 17px;
  }
}

.icon {
  &.icon {
    &-reportDevelopment {

      > span {
        box-sizing: border-box;

        &:nth-child(1) {
          transform: scale(1);
          top: 0;
          width: 25px;
          height: 30px;
          left: 2.5px;
          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(2) {
          transform: scale(1);
          top: 15px;
          width: 0px;
          height: 1px;
          left: 6.5px;
          animation: flyInLeftFullWidth ease-in 8s infinite;
        }

        &:nth-child(3) {
          transform: scale(1);
          top: 17px;
          width: 0px;
          height: 1px;
          left: 6.5px;
          animation: flyInLeftFullWidth ease-in 8s infinite;
          animation-delay: 1s;
        }

        &:nth-child(4) {
          transform: scale(1);
          top: 19px;
          width: 0px;
          height: 1px;
          left: 6.5px;
          animation: flyInLeftFullWidth ease-in 8s infinite;
          animation-delay: 2s;
        }

        &:nth-child(5) {
          transform: scale(1);
          top: 21px;
          width: 0px;
          height: 1px;
          left: 6.5px;
          animation: flyInLeftFullWidth ease-in 8s infinite;
          animation-delay: 3s;
        }

        &:nth-child(6) {
          transform: scale(1);
          top: 23px;
          width: 0px;
          height: 1px;
          left: 6.5px;
          animation: flyInLeftFullWidth ease-in 8s infinite;
          animation-delay: 4s;
        }

        &:nth-child(7) {
          transform: scale(1);
          top: 3px;
          width: 7px;
          height: 7px;
          left: 6.5px;
          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(8) {
          transform: scale(1);
          top: 3px;
          width: 7px;
          height: 7px;
          left: 16.5px;
          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }
      }
    }
  }
}
