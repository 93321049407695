@import "../../../Assets/StyleSheets/Variables/_colors.scss";
@import "../../../Assets/StyleSheets/Animations/_pulsate.scss";
@import "../../../Assets/StyleSheets/Animations/_colorFade.scss";

$golden-ratio: 1.61803398875;

@media (prefers-color-scheme: dark) {
  .square { 
    &.borderRight {
      border-right: 1px solid $secondary;
    }

    &.boxShadow {
      box-shadow: 1px 1px 5px 0px $secondary inset;
    }
  }
}

@media (prefers-color-scheme: light) {
  .square { 
    &.borderRight {
      border-right: 1px solid $primary;
    }

    &.boxShadow {
      box-shadow: 1px 1px 5px 0px $primary inset;
    }
  }
}

.square {
  flex: $golden-ratio;
  display: flex;
  background-color: transparent;
  animation-duration: 3s;

  animation-name: pulsate;

  &.animate {
    animation-name: colorFadeRainbowBackground;
  }

  &.animateInfinite {
    animation-iteration-count: infinite;
  }

  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;

  position: relative;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
