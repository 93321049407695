@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.joyStick {
  position: fixed;
  z-index: 99999;
  bottom: 0px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 120px;
  border-radius: 50%;
  box-shadow: 0 0 15px $primaryTransparent;

  @media (prefers-color-scheme: dark) {
    box-shadow: 0 0 15px $secondaryTransparent;
  }
  
  animation: pulsate;
  animation-duration: 3s;
  animation-iteration-count: infinite;

  &.active {
    bottom: 0;
    width: 300px;
    background: gold;
    padding-top: 80px;
    border-radius: 150px 150px 0 0;
  }

  .closeJoystick {
    position: absolute;
    z-index: 9999;
    left: 50%;
    top: -25px;
    background: $secondaryTransparent;
    border-radius: 50%;
    margin-left: -25px;

    @media (prefers-color-scheme: dark) {
      background: $primaryTransparent;
    }
  }

  .stick {
    display: flex;
    cursor: grab;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: auto;
    background-color: lightskyblue;
    overflow: hidden;

    color: $secondary;
    text-shadow: 0px 0px 6px $primary;
  }

  .innerStick {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    cursor: grabbing;
    box-shadow: $primaryTransparent -20px -20px 20px 0px inset,
                $primary -10px -10px 20px inset;
  }
}
