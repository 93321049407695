.icon {
  &-flag {
    &_en {
      filter: grayscale(1);
      -webkit-transition : -webkit-filter 200ms linear;
      transition : filter 200ms linear;

      &:hover {
        filter: grayscale(0);
      }

      &.active {
        filter: grayscale(0);
      }

      background: #00257d;

      > span {
        border-radius: 0;

        &:nth-child(1) {
          background: #ff0000;
          top: 50%;
          margin-top: -2px;
          width: 100%;
          height: 4px;
          z-index: 9999;
          transform: scale(1);
        }

        &:nth-child(2) {
          background: white;
          top: 50%;
          margin-top: -3px;
          width: 100%;
          height: 6px;
          z-index: 9998;
          transform: scale(1);
        }

        &:nth-child(3) {
          background: #ff0000;
          left: 50%;
          margin-left: -2px;
          width: 4px;
          height: 100%;
          z-index: 9999;
          transform: scale(1);
        }

        &:nth-child(4) {
          background: white;
          left: 50%;
          margin-left: -3px;
          width: 6px;
          height: 100%;
          z-index: 9998;
          transform: scale(1);
        }

        &:nth-child(5) {
          background: #fff;
          height: 5px;
          left: -6px;
          width: 145%;
          z-index: 99;
          transform: scale(1) rotate(30deg);
          top: 55%;
        }

        &:nth-child(6) {
          background: #fff;
          height: 5px;
          right: -6px;
          width: 145%;
          z-index: 99;
          transform: scale(1) rotate(-30deg);
          top: 55%;
        }

        &:nth-child(7) {
          background: #ff0000;
          height: 2px;
          right: -6px;
          width: 70%;
          z-index: 99;
          transform: scale(1) rotate(-30deg);
          top: 20.5%;
        }

        &:nth-child(8) {
          background: #ff0000;
          height: 2px;
          left: -6%;
          width: 70%;
          z-index: 99;
          transform: scale(1) rotate(330deg);
          bottom: 20.5%;
        }

        &:nth-child(9) {
          background: #ff0000;
          height: 2px;
          left: -6px;
          width: 70%;
          z-index: 99;
          transform: scale(1) rotate(30deg);
          top: 20.5%;
        }

        &:nth-child(10) {
          background: #ff0000;
          height: 2px;
          right: -6%;
          width: 70%;
          z-index: 99;
          transform: scale(1) rotate(30deg);
          bottom: 20.5%;
        }
      }
    }
  }
}