@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-arrowDown {
      > span {
        &:nth-child(1) {
          transform: rotate(45deg) scaleX(0.7);
          top: 13px;
          left: -7px;
          height: 1px;
        }

        &:nth-child(2) {
          transform: scale(0)
        }

        &:nth-child(3) {
          transform: rotate(-45deg) scaleX(0.7);
          top: 13px;
          left: 7px;
          height: 1px;
        }
      }

      &:hover {
        & span {
          &:nth-child(1) {
            top: 17px;
          }
    
          &:nth-child(3) {
            top: 17px;
          }
        }
      }
    }
  }
}
