@keyframes pulsate {
  10% {
    opacity: 0.9;
  }

  20% {
    opacity: 0.8;
  }

  30% {
    opacity: 0.7;
  }

  40% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.5;
  }
}
