@keyframes colorFadeRainbowColor {
  5% {
    color: transparent;
  }

  10% {
    color: rgb(255, 0, 17);
  }

  15% {
    color: rgb(255, 123, 0);
  }

  20% {
    color: rgb(254, 250, 2);
  }

  25% {
    color: rgb(26, 156, 0);
  }

  30% {
    color: rgb(0, 81, 255);
  }

  45% {
    color: rgb(195, 0, 255);
  }

  50% {
    color: transparent;
  }
}

@keyframes colorFadePrimaryColor {
  5% {
    color: transparent;
  }

  10% {
    color: pink;
  }

  20% {
    color: gold;
  }

  30% {
    color: rgba(0, 0, 0, 0.6);
  }

  40% {
    color: lightskyblue;
  }
}

@keyframes colorFadeRainbowBackground {
  5% {
    background-color: transparent;
  }

  10% {
    background-color: rgb(255, 0, 17);
  }

  15% {
    background-color: rgb(255, 123, 0);
  }

  20% {
    background-color: rgb(254, 250, 2);
  }

  25% {
    background-color: rgb(26, 156, 0);
  }

  30% {
    background-color: rgb(0, 81, 255);
  }

  35% {
    background-color: rgb(195, 0, 255);
  }

  40% {
    background-color: transparent;
  }
}

@keyframes colorFadePrimaryBackground {
  5% {
    background-color: transparent;
  }

  10% {
    background-color: pink;
  }

  25% {
    background-color: transparent;
  }

  35% {
    background-color: gold;
  }

  45% {
    background-color: rgba(0, 0, 0, 0.6);
  }

  55% {
    background-color: lightskyblue;
  }

  90% {
    background-color: transparent;
  }
}
