@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  box-sizing: border-box;
  justify-content: center;

  &.clear {
    clear: both;
  }

  &.container {
    height: 100vh;
  }

  &.borderBottom {
    border-bottom: 1px solid $primaryTransparent;
  }

  &.wrapper {
    height: 100vh;
    
    margin: auto;
    align-content: flex-start;

    &.maxWidth {
      max-width: 1300px;
    }
  }

  &.cursor {
    &__pointer {
      cursor: pointer;
    }
  }

  &.justifyContent {
    &__flex-start {
      justify-content: flex-start;
    }
  }

  &.flexDirection {
    &__row-reverse {
      flex-direction: row-reverse;
    }
  }

  .alignItems {
    &__stretch {
      align-items: stretch;
      flex-wrap: nowrap;
    }

    &__center {
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  &.type {
    &__card {
      backdrop-filter: blur(30px);

      @media (prefers-color-scheme: dark) {
        background-color: $primaryTransparent;
        box-shadow: 0px 0px 1px 0 $primaryTransparent;
      }

      @media (prefers-color-scheme: light) {
        background-color: $secondaryTransparent;
        box-shadow: 0px 0px 1px 0 $secondaryTransparent;
      }
    }
  }

  &.overflow {
    &__scrollY {
      overflow-y: scroll;
    }
  }

  &.spacing {
    &__5 {
      padding: 5px;
    }

    &__10 {
      padding: 10px;
    }

    &__20 {
      padding: 20px;
    }

    &__40 {
      padding: 40px;
    }

    &__50 {
      padding: 50px;
    }
  }

  &.columns {
    &__12 {
      width: 100%;
      float: left;
    }

    &__11 {
      @media(max-width: '800px') {
        width: 100%;
      }

      width: calc(100% / 12 * 11);
      float: left;
    }

    &__10 {
      @media(max-width: '800px') {
        width: 100%;
      }

      width: calc(100% / 12 * 10);
      float: left;
    }

    &__9 {
      @media(max-width: '800px') {
        width: 100%;
      }

      width: calc(100% / 12 * 9);
      float: left;
    }

    &__8 {
      @media(max-width: '800px') {
        width: 100%;
      }

      width: calc(100% / 12 * 8);
      float: left;
    }

    &__7 {
      @media(max-width: '800px') {
        width: 100%;
      }

      width: calc(100% / 12 * 7);
      float: left;
    }

    &__6 {
      @media(max-width: '800px') {
        width: 100%;
      }

      width: calc(100% / 12 * 6);
      float: left;
    }

    &__5 {
      @media(max-width: '800px') {
        width: 50%;
      }

      width: calc(100% / 12 * 5);
      float: left;
    }

    &__4 {
      @media(max-width: '800px') {
        width: 50%;
      }

      width: calc(100% / 12 * 4);
      float: left;
    }

    &__3 {
      @media(max-width: '800px') {
        width: 50%;
      }

      width: calc(100% / 12 * 3);
      float: left;
    }

    &__2 {
      @media(max-width: '800px') {
        width: 50%;
      }

      width: calc(100% / 12 * 2);
      float: left;
    }

    &__1 {
      @media(max-width: '800px') {
        width: 25%;
      }

      @media(max-width: '600px') {
        width: 50%;
      }

      width: calc(100% / 12);
      float: left;
    }
  }

  &.responsiveFullWidth {
    &__800 {
      @media(max-width: '800px') {
        width: 100%;
      }
    }

    &__600 {
      @media(max-width: '600px') {
        width: 100%;
      }
    }
  }

  
}