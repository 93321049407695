@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.score {
  width: 100%;
  background-color: $primary;
  position: relative;
  height: 5px;
  border-radius: 5px;

  @media (prefers-color-scheme: dark) {
    background-color: $secondary;
  }
  
  .bar {
    background: gold;
    height: 100%;
    position: absolute;
    border-radius: 0 5px 5px 0;
  }
}