@import "../../../Assets/StyleSheets/Variables/_colors.scss";

hr {
  &.divider {
    display: block;
    height: 1px;
    width: 100%;
    border-style: none;
    background: $primary;
    margin: 30px 0;

    @media (prefers-color-scheme: dark) {
      background: $secondary;
    }
  }
}