@import "../../../Assets/StyleSheets/Variables/_colors.scss";

$golden-ratio: 1.61803398875;

.goldenRatioBox {
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
}

@media (prefers-color-scheme: dark) {
  .goldenRatioBox {
    &.allBorders {
      border: 1px solid $secondary;
    }
  }
}

@media (prefers-color-scheme: light) {
  .goldenRatioBox {
    &.allBorders {
      border: 1px solid $primary;
    }
  }
}

@media (orientation: portrait) {
  .goldenRatioBox {
    width: 90%;
  }
}

@media (orientation: landscape) {
  .goldenRatioBox {
    width: 100%;
  }
}
