@import "./Assets/StyleSheets/Variables/_colors.scss";


@font-face {
  font-family: 'Roboto';
  src: url('./Assets/Fonts/Roboto/Roboto-Thin.ttf') format('truetype'); 
  font-weight: 100;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/Fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype'); 
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/Fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 200;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/Fonts/Roboto/Roboto-Italic.ttf') format('truetype'); 
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/Fonts/Roboto/Roboto-Regular.ttf') format('truetype'); 
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/Fonts/Roboto/Roboto-Bold.ttf') format('truetype'); 
  font-weight: 600;
  font-display: block;
}

@import "./Assets/StyleSheets/_reset.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// * {
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }


@media (prefers-color-scheme: dark) {
  body { background: $primary; color: $secondary; }
}

@media (prefers-color-scheme: light) {
  body { background: $secondary; color: $primary; }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #f50057;
  border-radius: 2px;
}
