@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-hamburger {
      > span {    
        &:nth-child(1) {
          transform: scale(1);
          top: 5px;
          left: 0px;
          height: 1px;
        }
    
        &:nth-child(2) {
          transform: scale(1);
          top: calc(50% - 1px);
          left: 0px;
          height: 1px;
        }
    
        &:nth-child(3) {
          transform: scale(1);
          bottom: 5px;
          left: 0px;
          height: 1px;
        }
      }
    
      &:hover {
        & span {
          &:nth-child(1) {
            top: 8px;
          }
    
          &:nth-child(3) {
            bottom: 8px;
          }
        }
      }
    }
  }
}
