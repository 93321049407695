@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@keyframes moveInLeft {
  0% {
    left: -100%;
  }

  30% {
    left: 0%;
  }
}

@keyframes moveInRight {
  0% {
    right: -100%;
  }

  30% {
    right: 0%;
  }
}

.icon {
  &.icon {
    &-prototyping {
      > span {
        border-radius: 0;
        
        &:nth-child(1) {
          transform: scale(1);
          top: 0px;
          width: 5px;
          height: 5px;
          left: 0px;
          animation: moveInLeft 5s infinite;
          
          background: transparent;
          
          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }
          
          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }
        
        &:nth-child(2) {
          transform: scale(1);
          top: 0px;
          width: 5px;
          height: 5px;
          right: 0px;
          animation: moveInRight 5s infinite;

          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(3) {
          transform: scale(1);
          bottom: 0px;
          width: 5px;
          height: 5px;
          right: 0px;
          animation: moveInRight 5s infinite;

          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(4) {
          transform: scale(1);
          bottom: 0px;
          width: 5px;
          height: 5px;
          left: 0px;
          animation: moveInLeft 5s infinite;

          background: transparent;

          @media (prefers-color-scheme: dark) {
            border: 1px solid $secondary;
          }

          @media (prefers-color-scheme: light) {
            border: 1px solid $primary;
          }
        }

        &:nth-child(5) {
          transform: scale(1);
          top: 10px;
          width: 1px;
          height: 10px;
          left: 3px;
        }

        &:nth-child(6) {
          transform: scale(1);
          top: 10px;
          width: 1px;
          height: 10px;
          right: 3px;
        }

        &:nth-child(7) {
          transform: scale(1);
          top: 3px;
          width: 10px;
          height: 1px;
          right: 10px;
        }

        &:nth-child(8) {
          transform: scale(1);
          bottom: 3px;
          width: 10px;
          height: 1px;
          right: 10px;
        }
      }
    }
  }
}
