@import "../../../Assets/StyleSheets/Variables/_colors.scss";
@import "../../../Assets/StyleSheets/Animations/_blink.scss";
@import "../../../Assets/StyleSheets/Animations/_colorFade.scss";

.rotatingText {
  // font-weight: 100;

  > span {
    &.text {
      display: block;
      float: none;
    }

    &.rotatingWord {
      margin: 0 4px;
    }

    &.active {
      display: initial;
    }

    > .scalingLetter {
      font-size: 100%;
      animation-duration: 2s;
      position: relative;
    }

    .scalingLetter {
      -webkit-transition: color 500ms;
      transition: color 500ms;
    }
  }

  & .color {
    &__primary {
      color: $primary;

      @media (prefers-color-scheme: dark) {
        color: $secondary;
      }
    }

    &__transparent {
      color: transparent;
    }

    &__dark {
      color: $primary;
    }
  }

  & .animation {
    &__colorFadeRainbowColor {
      animation-name: colorFadeRainbowColor;
    }

    &__colorFadePrimaryColor {
      animation-name: colorFadePrimaryColor;
    }
  }

  .blinking {
    @media (prefers-color-scheme: light) {
      -webkit-animation: 1s blinkPrimary step-end infinite;
      -moz-animation: 1s blinkPrimary step-end infinite;
      -ms-animation: 1s blinkPrimary step-end infinite;
      -o-animation: 1s blinkPrimary step-end infinite;
      animation: 1s blinkPrimary step-end infinite;
    }

    @media (prefers-color-scheme: dark) {
      -webkit-animation: 1s blinkSecondary step-end infinite;
      -moz-animation: 1s blinkSecondary step-end infinite;
      -ms-animation: 1s blinkSecondary step-end infinite;
      -o-animation: 1s blinkSecondary step-end infinite;
      animation: 1s blinkSecondary step-end infinite;
    }
  }

  .staticText {
    @media (prefers-color-scheme: light) {
      color: $primary; 
    }
    
    @media (prefers-color-scheme: dark) {
      color: $secondary; 
    }
  }
}
