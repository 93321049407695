@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.button {
  width: 100%;
  background: $primary;
  color: $secondary;
  text-align: center;
  padding: 10px 5px;

  @media (prefers-color-scheme: dark) {
    background: $secondary;
    color: $primary;
  }
  border: 0px;
  // display: block;
  cursor: pointer;

  &.buttonType {
    &__warn {
      background: orange;
    }
    &__alert {
      background: red;
    }
    &__success {
      background: rgb(0, 249, 0);
    }
  }
}