@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-play {
      > span {
        &:nth-child(1) {
          transform: rotate(45deg) scaleX(0.7);
          top: 7.5px;
          left: 0px;
          height: 1px;
        }

        &:nth-child(2) {
          transform: rotate(90deg) scaleX(0.95);
          top: 14px;
          left: -7px;
          height: 1px;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) scaleX(0.7);
          bottom: 7.5px;
          left: 0px;
          height: 1px;
        }
      }
    }
  }
}
