$primary: #131313;
$primaryTransparent: #13131342;
$secondary: white;
$secondaryTransparent: #ffffffa3;

$blue: #2300eb;

$warning: coral;
$success: greenyellow;

$dactivatedDark: #d7cece59;
$dactivatedLight: rgb(109, 109, 109);
