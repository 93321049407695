@import "../../../Assets/StyleSheets/Variables/_colors.scss";

@keyframes blinkSecondary {
  from, to {
    color: $secondary;
  }

  50% {
    color: transparent;
  }
}

@-moz-keyframes blinkSecondary {
  from, to {
    color: $secondary;
  }

  50% {
    color: transparent;
  }
}

@keyframes blinkPrimary {
  from, to {
    color: $primary;
  }

  50% {
    color: transparent;
  }
}

@-moz-keyframes blinkPrimary {
  from, to {
    color: $primary;
  }

  50% {
    color: transparent;
  }
}
