@import "../../../Assets/StyleSheets/Animations/_pulsate.scss";
@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.typography {
  font-family: 'Roboto';
  width: 100%;

  &.isLink {
    text-decoration: none;

    &:visited {
      color: $primary;

      @media (prefers-color-scheme: dark) {
        color: $secondary;
      }
    }
  }

  &.animateInfinite {
    animation-iteration-count: infinite;
    animation-duration: 3s;
  }

  &.animation {
    &__pulsate {
      animation-name: pulsate;
    }
  }

  &.defaultColor {
    &__dark {
      * {
        color: $primary;
      }
    }
  }
    
  &.textAlign {
    &__left {
      text-align: left;
    }

    &__right {
      text-align: right;

      @media(max-width: '800px') {
        text-align: left;
        margin: 20px 0 5px;
      }
    }

    &__center {
      text-align: center;
    }
  }

  &.wordWrap {
    &__breakWord {
      word-wrap: break-word;
    }
  }

  span {
    &.isLabel {
      font-size: 0.9em;
      font-weight: 600;
      color: $primaryTransparent;

      @media(max-width: '800px') {
        font-size: 1.2em;
      }

      @media (prefers-color-scheme: dark) {
        color: $secondaryTransparent;
      }
    }
  }

  .greetingText {
    font-size: 2em;
    margin-bottom: 20px;
    margin-top: 20px;
    display: block;
  }

  .subGreetingText {
    font-size: 1.5em;
    margin-bottom: 15px;
    margin-top: 15px;
    display: block;
  }

  .isCaption {
    font-size: 1em;
    position: relative;
    font-weight: 200;
    font-style: italic;
    font-family: 'Times New Roman';
  }

  &.isResponsive {
    h1 {
      font-size: 4vw;

      @media(min-width: '1200px') {
        font-size: 3em;
      }
    }

    h2 {
      font-size: 3.5vw;

      @media(min-width: '1200px') {
        font-size: 2.5em;
      }
    }

    h3 {
      font-size: 3vw;

      @media(min-width: '1200px') {
        font-size: 2em;
      }
    }
  }

  h1 {
    font-size: 2.5em;
    margin-top: 60px;
    margin-bottom: 30px;
    font-weight: 300;
    text-transform: uppercase;
    color: $primary;

    @media (prefers-color-scheme: dark) {
      color: $secondary;
    }
  }
  
  h2 {
    font-size: 2em;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: 300;
    text-transform: uppercase;
    color: $primary;

    @media (prefers-color-scheme: dark) {
      color: $secondary;
    }

  }
  
  h3 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: italic;
    text-transform: uppercase;
    color: $primary;

    @media (prefers-color-scheme: dark) {
      color: $secondary;
    }
  }

  h4 {
    font-size: 1em;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
