@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.cloud {
  display: block;
  z-index: 9999;
  position: relative;
  top: 20px;
  width: 300px;
  height: 180px;
  right: 0;

  // .cloudCenter {
  //   width: 300px;
  //   min-width: 300px;
  //   padding: 7px 60px;
  //   border-radius: 150px;
  //   box-shadow: 2px 2px $primary;
  //   position: absolute;
  //   box-sizing: border-box;
  //   left: 50%;
  //   margin-left: -150px;

  //   @media (prefers-color-scheme: dark) {
  //     box-shadow: 2px 2px $secondary;
  //   }
  // }

  // .cloudTopLeft {
  //   position: absolute;
  //   width: 89px;
  //   height: 62px;
  //   border-radius: 50%;
  //   top: -15px;
  //   left: 50%;
  //   margin-left: -90px;
  //   z-index: -2;
  //   box-shadow: 0px -2px $primary;

  //   @media (prefers-color-scheme: dark) {
  //     box-shadow: 0px -2px $secondary;
  //   }
  // }

  // .cloudTopRight {
  //   position: absolute;
  //   width: 104px;
  //   height: 98px;
  //   border-radius: 50%;
  //   top: -29px;
  //   left: 50%;
  //   margin-left: -15px;
  //   z-index: -1;
  //   box-shadow: 3px -5px $primary;

  //   @media (prefers-color-scheme: dark) {
  //     box-shadow: 3px -5px $secondary;
  //   }
  // }

  // .bubble1 {
  //   position: absolute;
  //   width: 10px;
  //   height: 10px;
  //   border-radius: 50%;
  //   top: 122px;
  //   left: -105px;
  //   z-index: -1;
  //   box-shadow: 2px 0px $primary;
  //   animation: scale 3s infinite;
  //   animation-delay: 100ms;

  //   @media (prefers-color-scheme: dark) {
  //     box-shadow: 2px 0px $secondary;
  //   }

  //   @media(max-width: '800px') {
  //     left: 50%;
  //     top: -90px;
  //     margin-left: -50px;
  //   }
  // }

  // .bubble2 {
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   border-radius: 50%;
  //   top: 114px;
  //   left: -80px;
  //   z-index: -1;
  //   box-shadow: 3px 0px $primary;
  //   animation: scale 3s infinite;
  //   animation-delay: 200ms;

  //   @media (prefers-color-scheme: dark) {
  //     box-shadow: 3px 0px $secondary;
  //   }

  //   @media(max-width: '800px') {
  //     left: 50%;
  //     top: -75px;
  //     margin-left: -75px;
  //   }
  // }

  // .bubble3 {
  //   position: absolute;
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 50%;
  //   top: 95px;
  //   left: -48px;
  //   z-index: -1;
  //   box-shadow: 4px 0px $primary;
  //   animation: scale 3s infinite;
  //   animation-delay: 500ms;

  //   @media (prefers-color-scheme: dark) {
  //     box-shadow: 4px 0px $secondary;
  //   }

  //   @media(max-width: '800px') {
  //     left: 50%;
  //     top: -44px;
  //     margin-left: -102px;
  //     width: 30px;
  //     height: 30px;
  //   }
  // }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
