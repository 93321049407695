@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  &.icon {
    &-pause {
      > span {
        &:nth-child(1) {
          transform: rotate(90deg) scaleX(1);
          left: -6px;
          top: 14px;
          height: 1px;
        }
  
        &:nth-child(2) {
          transform: rotate(90deg) scaleX(1);
          left: 6px;
          top: 14px;
          height: 1px;
        }
      }
    }
  }
}
