@import "../../../Assets/StyleSheets/Variables/_colors.scss";

.icon {
  width: 30px;
  height: 30px;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;

  &.width {
    &__50 {
      width: 50px;
    }
  }
  
  > span {
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    position: absolute;
    border-radius: 1px;
    height: 0px;
    transform: scale(0);
    width: 100%;

    @media (prefers-color-scheme: dark) {
      background-color: $secondary;
    }

    @media (prefers-color-scheme: light) {
      background-color: $primary;
    }
  }
}
