@import "../../../Assets/StyleSheets/Variables/_colors.scss";


.avatar {
  width: 200px;
    min-width: 200px;
    height: 200px;
    position: relative;
    border-bottom: 1px solid;
    overflow: hidden;
    background-size: 0;

  .head {
    position: relative;
    z-index: 3;

    .eye {
      width: 7px;
      height: 14px;
      background: black;
      position: absolute;
      top: 74px;
      animation: blink 3s ease-in-out infinite;

      border-radius: 20px;
      z-index: 1;

      &.eyeLeft {
        left: 81px;
      }

      &.eyeRight {
        left: 115px;
      }
    }

    .glass {
      width: 25px;
      height: 25px;
      border: 1px solid gold;
      border-radius: 50%;
      top: 72px;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      box-shadow: 0 0px 4px 0 $primary;

      .shine {
        &:before {
          content: "";
          position: absolute;
          width: 13px;
          height: 100px;
          background: white;
          opacity: 0.3;
          transform: rotate(30deg);
          top: -25px;
          left: -3px;
        }

        &:after {
          content: "";
          width: 2px;
          left: 13px;
          position: absolute;
          height: 100px;
          background: white;
          opacity: 0.3;
          transform: rotate(30deg);
          top: -25px;
        }
      }

      &.glassLeft {
        left: 70px;
      }

      &.glassRight {
        left: 105px;
      }
    }

    .glassBetween {
      width: 14px;
      height: 1px;
      background: gold;
      top: 77px;
      position: absolute;
      left: 94px;
      border-radius: 0;
      box-shadow: 0 0px 3px 0 black;
    }

    .hair {
      width: 90px;
      height: 85px;
      margin-left: -45px;
      background: #37332d;
      left: 50%;
      top: 15px;
      position: absolute;
      border-radius: 40%;
    }

    .hairStrand {
      width: 38px;
      height: 19px;
      background: #37332d;
      left: 89px;
      top: 13px;
      position: absolute;
      z-index: 1;
      transform: rotate(19deg);
      border-radius: 36px;
    }

    .circle1 {
      width: 51px;
      height: 85px;
      background: #f0a78b;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 40px;
      margin-left: -35.5px;
    }

    .circle2 {
      width: 51px;
      height: 85px;
      background: #f0a78b;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 36px;
      margin-left: -13.5px;
    }

    .circle3 {
      width: 65px;
      height: 70px;
      background: #f0a78b;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 75px;
      margin-left: -31px;
    }

    .ear {
      width: 13px;
      height: 30px;
      background: #f0a78b;
      border-radius: 50%;
      position: absolute;
      top: 80px;

      &.earLeft {
        left: 58px;
        transform: rotate(345deg);
      }

      &.earRight {
        left: 130px;
        transform: rotate(17deg);
      }
    }
  }

  .neck {
    position: absolute;
    width: 50px;
    height: 70px;
    background: #f1ad92;
    left: 50%;
    top: 130px;
    box-shadow: inset 0px 16px 10px 0px #ff8d62;
    margin-left: -25px;
  }

  .body {
    width: 130px;
    height: 35px;
    background: #484848;
    position: absolute;
    left: 50%;
    top: 165px;
    z-index: 2;
    margin-left: -65px;
    border-radius: 10px 10px 0 0;
    box-shadow: 1px 1px 3px 0 inset #aaa8a8;

    &:before {
      content: "";
      position: absolute;
      border-bottom: 20px solid #cccccc;
      border-left: 15px solid transparent;
      border-right: 0px solid transparent;
      height: 0;
      width: 25px;
      right: 15px;
      top: -20px;
      border-radius: 0 10px 0 0;
    }

    &:after {
      content: "";
      position: absolute;
      border-bottom: 20px solid #e6e6e6;
      border-left: 20x solid transparent;
      border-right: 15px solid transparent;
      height: 0;
      width: 22px;
      right: 75px;
      top: -20px;
      border-radius: 10px 0 0 0;
    }

    .dot {
      width: 20px;
      height: 20px;
      background: darkgrey;
      left: 50%;
      position: absolute;
      top: 24px;
      margin-left: -10px;
      border-radius: 50%;
      box-shadow: 0 0 5px inset white;
    }
  }
}

@keyframes blink {
  0% {
    transform: scaleY(1);
  }

  18% {
    transform: scaleY(1);
  }

  20% {
    transform: scaleY(0);
  }

  25% {
    transform: scaleY(1);
  }

  38% {
    transform: scaleY(1);
  }

  40% {
    transform: scaleY(0);
  }

  45% {
    transform: scaleY(1);
  }

  80% {
    transform: scaleY(1);
  }
}